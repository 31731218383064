<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
                <div class="my-md">
                    <a-row style="padding-left: 20px">
                        <a-col :span="10">
                            考勤周期从
                            <a-input-number :min="1" :max="28" v-model="value" @change="onChange" style="width: 70px"/>
                            号到
                            <span v-if="value==1">月底</span>
                            <span v-else>
                                {{value?value- 1:""}}号
                            </span>
                        </a-col>
                    </a-row>
                    <a-row style="padding-left: 100px;margin: 20px 0px">
                        <a-col :span="10">
                            <i style="color: red;padding-right: 5px">*</i>结束日就是开始日期的前一天
                        </a-col>
                    </a-row>
                    <a-row style="padding-left: 100px">
                        <a-col :span="10">
                            备注:验证开始日期不能大于28号
                        </a-col>
                    </a-row>
                    <a-row>
                        <div style="text-align: center;width: 300px;margin-top: 30px">
                            <a-button :type="'primary'" @click="handleSubmit">
                                <a-icon type="save"/>
                                <span>{{l("Save")}}</span>
                            </a-button>
                        </div>
                    </a-row>
                </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {AppComponentBase} from "../../../shared/component-base";
    import {
        CreateOrUpdateEnterpriseAttendCycleInput,
        EnterpriseAttendCycleEditDto,
        EnterpriseAttendCycleServiceProxy
    } from "../../../shared/service-proxies";

    export default {
        name: "attendance-setting",
        mixins: [AppComponentBase],
        data() {
            return {
                zh_CN,
                spinning: false,
                value: 26,
                entity: new EnterpriseAttendCycleEditDto()
            }
        },
        created() {
            this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {

            this.getData();
        },
        methods: {
            getData() {
                this.spinning = true;
                this._enterpriseAttendCycleServiceProxy.getForEdit()
                    .finally(() => {
                        this.spinning = false
                    })
                    .then(res => {
                        this.entity = res.enterpriseAttendCycle;
                        if (this.entity.startDay) {
                            this.value = this.entity.startDay;
                        } else {
                            this.entity.startDay = 26;
                        }
                    })
            },
            onChange(e) {
                if (e > 28) {
                    this.entity.startDay = 28;
                } else {
                    this.entity.startDay = e;
                }
            },
            handleSubmit() {
                let input = new CreateOrUpdateEnterpriseAttendCycleInput();
                if (!this.entity.startDay) {
                    return abp.message.warn("周期不能为空");
                }
                if (this.entity.startDay == 0) {
                    return abp.message.warn("周期不能为0");
                }
                input.enterpriseAttendCycle = this.entity;
                this.spinning = true;
                this._enterpriseAttendCycleServiceProxy.createOrUpdate(input)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(res => {
                        this.$notification.success({
                            description: undefined,
                            message: this.l("SavedSuccessfully")
                        });
                        this.getData();
                    })
            }
        }
    }
</script>

<style scoped>

</style>
